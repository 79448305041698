import * as Sentry from "@sentry/browser";
import { ParentProps } from "solid-js";
import { HttpStatusCode } from "@solidjs/start";

import { Typography } from "#root/components/Typography";
import { VStack } from "#style/jsx";
import { SEO } from "#root/components/SEO";
import { PageLayout } from "#root/layouts/PageLayout";

type ErrorProps = ParentProps<{
  error?: unknown;
  shouldLogError?: boolean;
  code: number;
}>;

export function ErrorPage(props: ErrorProps) {
  if (props.shouldLogError) {
    Sentry.captureException(props.error);
  }

  return (
    <PageLayout>
      <VStack justifyContent="center" alignItems="center">
        <SEO noIndex />
        <HttpStatusCode code={props.code} />
        <Typography textStyle="titleDisplay">It's SO over</Typography>
        <Typography textStyle="titleDisplay">Error {props.code}</Typography>
        <Typography textStyle="body">
          Don't know how you ended here, but that's an error!
        </Typography>
      </VStack>
    </PageLayout>
  );
}
